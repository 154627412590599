'use client';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

interface ContentfulImageProps {
	src: string;
	width?: number;
	quality?: number;
	[key: string]: any; // For other props that might be passed
	className?: string;
}

const contentfulLoader = ({ src, width, quality }: ContentfulImageProps) => {
	return `${src}?w=${width}&q=${quality || 75}&fm=webp`;
};

export default function ContentfulImage(props: ContentfulImageProps) {
	return (
		<div className={twMerge('h-full w-full overflow-hidden', props?.className)}>
			<Image
				alt={props.alt}
				loader={contentfulLoader}
				{...props}
				className="h-full w-full object-cover"
			/>
		</div>
	);
}

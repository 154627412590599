import ContentfulImage from '@components/ContentfulImage'
import TestimonialCard from '@components/TestimonialCard'
import {
    type Options,
    documentToReactComponents,
} from '@contentful/rich-text-react-renderer'
import {
    BLOCKS,
    type Block,
    INLINES,
    type Inline,
} from '@contentful/rich-text-types'
import clsx from 'clsx'
import Link from 'next/link'
import type { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import Company from './Company'

interface RichTextProps {
    content: any
    prose?: string
    className?: string
}

function RichText({ content, prose, className }: RichTextProps) {
    const options: Options = {
        renderNode: {
            [INLINES.HYPERLINK]: (
                node: Block | Inline,
                children: ReactNode,
            ) => {
                return (
                    <Link
                        href={node.data.uri}
                        title="View more information"
                        //target={node.data.uri.startsWith('/') ? '' : '_blank'}
                    >
                        {children}
                    </Link>
                )
            },
            [BLOCKS.OL_LIST]: (node: Block | Inline, children: ReactNode) => {
                return <ol className="list-decimal">{children}</ol>
            },

            [BLOCKS.TABLE]: (node: Block | Inline, children: ReactNode) => {
                return (
                    <table className="not-prose overflow-hidden bg-white dark:bg-transparent">
                        <tbody>{children}</tbody>
                    </table>
                )
            },
            [BLOCKS.TABLE_CELL]: (
                node: Block | Inline,
                children: ReactNode,
            ) => {
                return (
                    <td className="space-y-2 border border-gray-200 px-4 py-2 align-top">
                        {children}
                    </td>
                )
            },
            [BLOCKS.TABLE_HEADER_CELL]: (
                node: Block | Inline,
                children: ReactNode,
            ) => {
                return (
                    <th className="border border-gray-200 bg-gray-200 px-4 py-1 text-left font-heading dark:bg-black dark:bg-gray-700">
                        {children}
                    </th>
                )
            },
            [BLOCKS.QUOTE]: (node: Block | Inline, children: ReactNode) => {
                return <blockquote>{children}</blockquote>
            },
            [BLOCKS.EMBEDDED_ASSET]: (
                node: Block | Inline,
                children: ReactNode,
            ) => {
                if (content?.links) {
                    const asset = content?.links.assets.block.find(
                        (i: any) => i.sys.id === node.data.target.sys.id,
                    )
                    if (asset) {
                        return (
                            <figure
                                className={clsx(
                                    'not-prose group space-y-2',
                                    className,
                                )}
                            >
                                <div className="aspect-video overflow-hidden rounded-md shadow-lg">
                                    <ContentfulImage
                                        src={asset?.url}
                                        alt={asset?.description}
                                        width={1080}
                                        height={800}
                                        quality={80}
                                    />
                                </div>
                                {asset?.description && (
                                    <p className="flex items-center justify-center text-center text-sm transition-all delay-100 duration-500">
                                        <span className="mr-2 inline-block rounded border border-hot-black/60 bg-white/20 px-2 py-0.5 text-hot-black text-xs uppercase dark:bg-white dark:text-hot-black">
                                            {asset?.contentType?.includes(
                                                'video',
                                            )
                                                ? 'Video'
                                                : 'Image'}
                                        </span>{' '}
                                        {asset?.description}
                                    </p>
                                )}
                            </figure>
                        )
                    }
                }
            },
            [BLOCKS.EMBEDDED_ENTRY]: (
                node: Block | Inline,
                children: ReactNode,
            ) => {
                if (content?.links?.entries?.block) {
                    const entry = content?.links?.entries?.block.find(
                        (i: any) => i?.sys?.id === node?.data?.target?.sys?.id,
                    )
                    if (!entry) {
                        return
                    }
                    if (entry.__typename) {
                        switch (entry.__typename.toLowerCase()) {
                            case 'company':
                                return <Company company={entry} />
                            case 'testimonials':
                                return <TestimonialCard item={entry} />
                        }
                    }
                }
            },
        },
    }

    if (!content) {
        return null
    }

    return (
        <div
            className={twMerge(
                prose
                    ? prose
                    : 'prose-black prose-sm md:prose-lg dark:prose-invert space-y-4 md:space-y-6',
                className,
            )}
        >
            {documentToReactComponents(content?.json, options)}
        </div>
    )
}

export default RichText

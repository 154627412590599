'use client';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { ReactPlayerProps } from 'react-player';
import { BaseReactPlayerProps } from 'react-player/base';

const Video: React.FC<BaseReactPlayerProps> = ({
	url,
	playing,
	loop,
	muted,
	className,
	width,
	height,
	playsInline,
	fallback,
}) => {
	if (!url) {
		return null;
	}

	const VideoLoader = dynamic<ReactPlayerProps>(() => import('react-player'), {
		ssr: false,
	});

	return (
		<VideoLoader
			url={url}
			playing={playing}
			loop={loop}
			muted={muted}
			className={clsx('object-cover [&>video]:object-cover', className)}
			width={width}
			height={height}
			playsInline={playsInline}
			fallback={fallback}
		/>
	);
};
export default Video;

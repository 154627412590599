import ContentfulImage from '@components/ContentfulImage'
import HexRgba from '@lib/HexRgba'
import type { CompanyFragment } from '@queries/fragments/company.fragment'
import type { FragmentOf } from 'gql.tada'
import Link from 'next/link'

const Company: React.FC<{ company: FragmentOf<typeof CompanyFragment> }> = ({
    company,
}) => {
    return (
        <article
            className="not-prose nice-transition group relative h-full shrink-0 rounded-md border bg-cream/60 p-5 transition-all dark:border-cream/40 dark:bg-transparent"
            style={
                company.primaryColor
                    ? {
                          backgroundColor: HexRgba(company.primaryColor, 10),
                      }
                    : {}
            }
        >
            <div className="flex items-center space-x-8">
                {company.logo?.url && (
                    <div className="max-w-20 object-contain">
                        <ContentfulImage
                            src={company.logo.url}
                            width={64}
                            height={64}
                            alt={company.name || 'Avatar'}
                        />
                    </div>
                )}
                <div>
                    <h3 className="mb-0.5 font-semibold text-lg">
                        {company.name}
                    </h3>
                    {company?.summary && (
                        <p className="mb-0.5 font-semibold text-lg">
                            {company.summary}
                        </p>
                    )}
                    {company?.website && (
                        <Link
                            target="_blank"
                            className="mb-0.5 text-base hover:underline"
                            href={company.website}
                        >
                            {company.website}
                        </Link>
                    )}
                </div>
            </div>
        </article>
    )
}

export default Company

'use client'
import { buttonVariants } from '@components/Button'
import ContentfulImage from '@components/ContentfulImage'
import type { TestimonialsFragment } from '@queries/fragments/testimonials.fragment'
import { DialogTitle } from '@radix-ui/react-dialog'
import type { FragmentOf } from 'gql.tada'
import { twMerge } from 'tailwind-merge'
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from './Dialog'
import RichText from './RichText'
import { Wrap } from './Wrap'

const TestimonialDialog = ({
    item,
    children,
}: {
    item: FragmentOf<typeof TestimonialsFragment>
    children: React.ReactNode
}) => (
    <Dialog>
        <DialogTrigger asChild className="h-full cursor-pointer text-left">
            {children}
        </DialogTrigger>
        <DialogContent>
            <DialogTitle className="sr-only">Testimonial</DialogTitle>
            <DialogHeader>
                <div className="mb-4 flex items-center space-x-3">
                    {item.avatar?.url && (
                        <ContentfulImage
                            className="size-16 shrink-0 rounded-md shadow-lg"
                            src={item.avatar.url}
                            width={86}
                            height={86}
                            alt={item.name || 'Avatar'}
                        />
                    )}
                    <div>
                        <h3 className="mb-0.5 font-semibold text-xl">
                            {item.name}
                        </h3>
                        <p className="font-medium text-base">{item.jobTitle}</p>
                    </div>
                </div>
            </DialogHeader>
            <blockquote>
                <RichText
                    content={item.content}
                    className="dark:prose-invert inline font-medium"
                    prose="prose md:prose-lg"
                />
            </blockquote>
        </DialogContent>
    </Dialog>
)

const TestimonialCard = ({
    item,
}: {
    item: FragmentOf<typeof TestimonialsFragment>
}) => (
    <Wrap
        key={item.sys?.id}
        condition={item.content !== null}
        wrapper={(children) => (
            <TestimonialDialog item={item}>{children}</TestimonialDialog>
        )}
    >
        <article className="not-prose nice-transition group relative flex h-full shrink-0 cursor-pointer flex-col justify-between space-y-4 rounded-md border bg-cream/60 p-4 transition-all hover:border-gray-300 hover:bg-cream hover:shadow-md dark:border-cream/40 dark:bg-transparent dark:hover:bg-black">
            <div className="flex items-center space-x-3">
                {item.avatar?.url && (
                    <ContentfulImage
                        className="size-14 shrink-0 rounded-full shadow-lg"
                        src={item.avatar.url}
                        width={64}
                        height={64}
                        alt={item.name || 'Avatar'}
                    />
                )}
                <div>
                    <h3 className="mb-0.5 font-semibold text-sm">
                        {item.name}
                    </h3>
                    <p className="line-clamp-2 font-medium text-sm">
                        {item.jobTitle}
                    </p>
                </div>
            </div>
            <div className="space-y-4">
                <blockquote className="line-clamp-4">
                    <RichText
                        content={item.content}
                        prose="not-prose"
                        className="dark:prose-invert inline font-medium"
                    />
                </blockquote>
            </div>
            <div
                className={twMerge(
                    buttonVariants({ variant: 'outline', size: 'sm' }),
                    'group-hover:-translate-y-0.5 self-start transition-all group-hover:shadow-md',
                )}
            >
                Read Testimonial
            </div>
        </article>
    </Wrap>
)
export default TestimonialCard

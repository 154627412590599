import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

const buttonVariants = cva(
    'nice-transition group/button inline-flex items-center justify-center gap-x-1 whitespace-nowrap rounded-lg font-heading font-semibold text-sm ring-offset-background transition-all transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'bg-brand text-white hover:bg-black dark:bg-cream dark:text-black dark:hover:text-cream',
                destructive:
                    'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                outline:
                    'border border-2 bg-brand text-cream hover:bg-black dark:bg-cream dark:text-brand dark:hover:bg-black dark:hover:text-cream',
                secondary:
                    'border-2 border-brand bg-cream text-brand hover:bg-brand hover:text-cream dark:bg-cream dark:text-brand dark:hover:bg-black dark:hover:text-cream',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 rounded-lg px-2 text-xs md:px-3 md:text-sm',
                lg: 'h-11 rounded-lg px-8',
                icon: 'h-10 w-10',
            },
        },
        defaultVariants: {
            variant: 'outline',
            size: 'default',
        },
    },
)

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : 'button'
        return (
            <Comp
                className={twMerge(
                    buttonVariants({ variant, size, className }),
                )}
                ref={ref}
                {...props}
            />
        )
    },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
